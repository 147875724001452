.projectItem {
  border: 1px solid #ccc;
  display: flex;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  min-height: 300px;
  width: 80%;
  margin: 0 auto;
}

.projectItem .projectImage {
  width: 450px;
  height: 300px;
}

.projectItem .projectImage img {
  width: 100%;
  height: 100%;
}

.projectItem .projectImage:hover {
  cursor: pointer;
}

.projectItem .projectDetails {
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.projectItem .projectDetails h1 {
  height: auto;
  text-align: left;
  font-size: 24px;
  font-weight: 500;
  color: var(--primary-color);
}

.projectItem .projectDetails h1:hover {
  cursor: pointer;
}

.projectItem .projectDetails .projectTags {
  padding: 5px 0;
  margin-bottom: 10px;
  color: #888888;
}

.projectItem .projectDetails p {
  line-height: 1.7em;
  font-size: 15px;
  color: #424242;
  flex: 1;
  margin-bottom: 20px;
}

.projectBtns {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
  padding: 5px 14px;
  color: var(--primary-color);
  background-color: transparent;
  border: 2px solid var(--primary-color);
  cursor: pointer;
  text-decoration: none;
  height: 40px;
}

.btn:first-of-type {
  margin-right: 0.5em;
}

.btn svg {
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  .projectItem {
    display: block;
    width: 100%;
  }
  .projectItem .projectImage {
    width: auto;
    height: 230px;
  }

  .projectTags {
    position: block;
  }

  .projectItem .projectDetails p {
    max-height: 200px;
  }
}

@media only screen and (min-width: 610px) and (max-width: 1250px) {
  .projectItem {
    width: 100%;
  }
}
