.projects {
  min-height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 70px 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.projects h1 {
  text-align: center;
  height: 70px;
  font-size: 2.5rem;
  font-weight: 300;
  text-transform: uppercase;
}

.projects .projectsList {
  display: grid;
  grid-template-columns: auto;
  max-width: 95%;
  gap: 1em;
}

/* Phone */
@media only screen and (max-width: 600px) {
  .projects .projectsList {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
  }
}
