.home {
  font-family: "Arial", sans-serif;
  color: #3e497a;
}

.about {
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--primary-color);
  color: #f0f0f0;
  padding: 20px;
}

.about h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 60px;
  color: var(--secondary-color);
  margin-bottom: 20px;
  width: 500px;
}

.about .prompt {
  min-height: 200px;
}

.about .prompt p {
  font-size: 20px;
  text-align: left;
}

.prompt .links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.prompt .links svg {
  font-size: 40px;
  margin: 5px;
  color: var(--bg-color);
}

.scrollContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  color: var(--secondary-color);
  height: 100%;
}

.crollDown {
  border: 2px solid var(--secondary-color);
  border-radius: 20px;
  height: 50px;
  width: 30px;
  position: relative;
}

.crollDown::before {
  animation: MoveAnimation 2s infinite;
  background-color: var(--secondary-color);
  border-radius: 50%;
  content: "";
  height: 6px;
  width: 6px;
  position: absolute;
  top: 10px;
  transform: translateX(-50%);
}

@keyframes MoveAnimation {
  0% {
    transform: translate(-50%, 7px);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, 20px);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 30px);
    opacity: 0;
  }
}

.skills {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  height: calc(100vh - 70px);
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.skills h1 {
  padding-top: 10px;
  font-size: 3.5rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
}

.skills .list {
  margin: 10px 0;
  list-style: none;
  flex: 1;
}

.skills .list .item {
  margin-bottom: 10px;
}

.skills .list h2 {
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: 600;
}

.list span {
  font-size: 18px;
}

/* Small than 600px */
@media only screen and (max-width: 600px) {
  .about h2 {
    font-size: 20px;
    width: 350px;
  }

  .about h2 .name {
    margin: 10px;
    font-size: 30px;
    width: 350px;
  }

  .about .prompt p {
    text-align: center;
  }
}
