.navbar {
  background: var(--primary-color);
  height: 70px;
  width: 100%;
  display: flex;
}

.navbar__wrapper {
  width: 90%;
  display: flex;
  margin: 0 auto;
}

.brand-logo {
  width: fit-content;
}

.brand-logo a {
  text-decoration: none;
}
h3 {
  color: var(--bg-color);
  font-size: 22px;
  margin-left: 10px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

.toggleButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.toggleButton svg {
  font-size: 2rem;
}

.toggleButton button {
  cursor: pointer;
  margin-right: 20px;
  background: transparent;
  border: none;
  color: var(--bg-color);
}

.navbar .links {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar .links a {
  color: var(--bg-color);
  font-size: 18px;
  text-decoration: none;
  padding: 10px;
  margin: 10px;
  text-transform: uppercase;
}

#close .toggleButton {
  display: none;
}

/* Small than 600px */
@media only screen and (max-width: 600px) {
  .navbar__wrapper {
    width: 100%;
  }
  #close .toggleButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 70px;
  }

  .navbar .links {
    position: absolute;
    top: 70px;
    left: 0;
  }

  #close .links {
    display: none;
  }

  #open .links {
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--primary-color);
    min-height: calc(100vh - 140px);
  }
}
